<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="Array.isArray(webhookKeyList)">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Webhook Keys ({{ webhookKeyList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon :to="{ name: 'organization-create-webhook-key', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <p class="mb-0 pb-0" v-if="webhookKeyList.length === 0">
                                No webhook keys found
                            </p>
                            <v-simple-table v-if="webhookKeyList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Label</th>
                                            <th>Cipher</th>
                                            <th>Key Length</th>
                                            <th>Not Before</th>
                                            <th>Not After</th>
                                            <th>Created on</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="webhookKey in webhookKeyList" :key="webhookKey.id">
                                            <td>
                                                <router-link :to="viewWebhookKeyLink(webhookKey)">{{ webhookKeyIdText(webhookKey.id) }}</router-link>
                                            </td>
                                            <td>
                                                {{ webhookKey.label }}
                                            </td>
                                            <td>
                                                {{ webhookKey.cipher_alg }}
                                            </td>
                                            <td>
                                                {{ webhookKey.key_length }}
                                            </td>
                                            <td>
                                                {{ formatDate(webhookKey.not_before) }}
                                            </td>
                                            <td>
                                                {{ formatDate(webhookKey.not_after) }}
                                            </td>
                                            <td>
                                                {{ formatDate(webhookKey.created_on) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
    },
    data: () => ({
        webhookKeyList: null,
        error: null,
        forbiddenError: false,
        account: null, // only if query specifies an account; otherwise we search all accounts
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
    },
    watch: {
        $route() {
            this.loadWebhookKeyList();
        },
    },
    methods: {
        async loadWebhookKeyList() {
            try {
                this.$store.commit('loading', { loadWebhookKeyList: true });
                const query = {
                    // account_id: null,
                };
                // if (this.$route.query.account_id) {
                //     query.account_id = this.$route.query.account_id;
                // }
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.search(query);
                if (response?.list) {
                    this.webhookKeyList = response.list;
                } else {
                    this.webhookKeyList = null;
                }
            } catch (err) {
                console.error('loadWebhookKeyList failed', err);
            } finally {
                this.$store.commit('loading', { loadWebhookKeyList: false });
            }
        },
        viewWebhookKeyLink(item) {
            return { name: 'organization-edit-webhook-key', params: { organizationId: this.$route.params.organizationId }, query: { id: item.id } };
        },
        formatDate(timestamp) {
            if (Number.isInteger(timestamp)) {
                return new Date(timestamp).toLocaleDateString();
            }
            return '';
        },
        webhookKeyIdText(webhookKeyId) {
            return formatId(webhookKeyId);
        },
    },
    mounted() {
        this.loadWebhookKeyList();
    },
};
</script>
